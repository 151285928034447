var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"height":"80px","app":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticClass:"d-flex d-md-none"},'v-app-bar-nav-icon',attrs,false),on))]}}])},_vm._l((_vm.nav_links),function(nav_link){return _c('v-list',{key:nav_link.id},[(nav_link.link)?_c('v-list-item',{attrs:{"href":nav_link.href}},[_c('v-leist-item-title',[_vm._v(_vm._s(nav_link.name))])],1):_vm._e(),(nav_link.value)?_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(nav_link.name)+" ")])]}}],null,true)},[_c('v-list',{staticClass:"mainMenu",attrs:{"dense":""}},_vm._l((_vm.links),function(link){return _c('v-list-item',{key:link.name,attrs:{"data":link,"href":link.href}},[_c('v-list-item-title',[_vm._v(_vm._s(link.name))])],1)}),1)],1):_vm._e(),(nav_link.value2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(nav_link.name)+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.links2),function(link){return _c('v-list-item',{key:link.name,attrs:{"data":link,"href":link.href}},[_c('v-list-item-title',[_vm._v(_vm._s(link.name))])],1)}),1)],1):_vm._e(),(nav_link.mobile)?_c('v-list-item',{attrs:{"href":nav_link.href}},[_c('v-list-item-title',[_vm._v(_vm._s(nav_link.name))])],1):_vm._e()],1)}),1),_c('span',{staticClass:"logoMedtime"},[_vm._v("Медтайм")]),_vm._l((_vm.nav_links),function(nav_link){return _c('v-toolbar-items',{key:nav_link.id,staticClass:"hidden-sm-and-down",attrs:{"data":nav_link}},[(nav_link.link)?_c('v-btn',{attrs:{"plain":"","href":nav_link.href,"title":nav_link.name}},[_c('span',[_vm._v(_vm._s(nav_link.name))])]):_vm._e(),(nav_link.value)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(nav_link.name)+" ")])]}}],null,true)},[_c('v-list',{staticClass:"mainMenu"},_vm._l((_vm.links),function(link){return _c('v-list-item',{key:link.name,attrs:{"data":link,"href":link.href}},[_c('v-list-item-title',[_vm._v(_vm._s(link.name))])],1)}),1)],1):_vm._e(),(nav_link.value2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(nav_link.name)+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.links2),function(link){return _c('v-list-item',{key:link.name,attrs:{"data":link,"href":link.href}},[_c('v-list-item-title',[_vm._v(_vm._s(link.name))])],1)}),1)],1):_vm._e()],1)}),_c('v-spacer'),_c('h3',{staticClass:"phoneNumber hidden-md-and-down"},[_c('v-icon',{attrs:{"color":"red","large":""}},[_vm._v("mdi-phone")]),_vm._v("8 (81153) 3-93-09")],1),_c('v-btn',{staticClass:"phoneNumber d-lg-none",attrs:{"href":"tel:88115339309","dark":"","color":"red"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-phone")])],1),_c('v-spacer'),_vm._l((_vm.buttons),function(button){return _c('v-btn',{key:button.name,staticClass:"navButtonsRed mx-1 hidden-md-and-down",attrs:{"data":button,"dark":"","color":"red","href":button.href}},[_vm._v(" "+_vm._s(button.name)+" ")])}),_vm._l((_vm.buttons),function(button){return _c('v-btn',{key:button.name,staticClass:"navButtonsRed mx-1 d-lg-none",attrs:{"data":button,"dark":"","color":"red","href":button.href}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(button.icon)+" ")])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }