<template>
  <v-app>
    <v-app-bar height="80px" app>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            class="d-flex d-md-none"
          ></v-app-bar-nav-icon>
        </template>
        <v-list v-for="nav_link in nav_links" v-bind:key="nav_link.id">
          <v-list-item v-if="nav_link.link" :href="nav_link.href">
            <v-leist-item-title>{{ nav_link.name }}</v-leist-item-title>
          </v-list-item>

          <v-menu v-if="nav_link.value" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                {{ nav_link.name }}
              </v-list-item>
            </template>
            <v-list class="mainMenu" dense>
              <v-list-item
                v-for="link in links"
                v-bind:data="link"
                v-bind:key="link.name"
                :href="link.href"
              >
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-if="nav_link.value2" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item  v-bind="attrs" v-on="on">
              {{ nav_link.name }}
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              v-for="link in links2"
              v-bind:data="link"
              v-bind:key="link.name"
              :href="link.href"
            ><v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
          <v-list-item v-if="nav_link.mobile" :href="nav_link.href">
            <v-list-item-title>{{ nav_link.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span
        class="logoMedtime"
        
      >Медтайм</span>
      <v-toolbar-items
        v-for="nav_link in nav_links"
        v-bind:data="nav_link"
        v-bind:key="nav_link.id"
        class="hidden-sm-and-down"
      >
        <v-btn
          v-if="nav_link.link"
          plain
          :href="nav_link.href"
          :title="nav_link.name"
          ><span>{{ nav_link.name }}</span></v-btn
        >
        <v-menu v-if="nav_link.value" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" dark v-bind="attrs" v-on="on">
              {{ nav_link.name }} 
            </v-btn>
          </template>
          <v-list class="mainMenu">
            <v-list-item
              v-for="link in links"
              v-bind:data="link"
              v-bind:key="link.name"
              :href="link.href"
            >
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu v-if="nav_link.value2" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  color="red" dark v-bind="attrs" v-on="on">
              {{ nav_link.name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="link in links2"
              v-bind:data="link"
              v-bind:key="link.name"
              :href="link.href"
            ><v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
 
      <v-spacer></v-spacer>

      <h3 class="phoneNumber hidden-md-and-down"><v-icon color="red" large>mdi-phone</v-icon>8 (81153) 3-93-09</h3>
      <v-btn href="tel:88115339309"  dark color="red" class="phoneNumber d-lg-none"><v-icon  large>mdi-phone</v-icon></v-btn>
      
      <v-spacer></v-spacer>
      <v-btn
        v-for="button in buttons"
        v-bind:data="button"
        v-bind:key="button.name"
        class="navButtonsRed mx-1 hidden-md-and-down"
        dark
        color="red"
        :href="button.href"
      >
        {{ button.name }}
      </v-btn>
     
      <v-btn
        v-for="button in buttons"
        v-bind:data="button"
        v-bind:key="button.name"
        class="navButtonsRed mx-1 d-lg-none"
        dark
        color="red"
        :href="button.href"
      >
        <v-icon dark>
          {{ button.icon }}
        </v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Add a navigation bar -->
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    buttons: [
      {
        id: 2,
        name: "Записаться на прием",
        href: "https://medluki.ru/raspisanie-priemov.html",
        icon: "mdi-pencil-plus",
      },
      {
        id: 22,
        name: "Записаться онлайн консультацию",
        href: "https://medluki.ru/onlain_konsultaciya.html",
        icon: "mdi-pencil-plus",
      }

    ],
    nav_links: [
      { id: 1, name: "Главная", href: "https://medluki.ru/", link: true },
      {
        id: 2,
        name: "Прайс",
        href: "http://cabinet.medluki.ru/price",
        link: true,
      },
      { id: 4, name: "▼ Услуги", value: "action" },
     
      {
        id: 6,
        name: "Расписание Приемов",
        href: "https://medluki.ru/raspisanie-priemov.html",
        link: true,
      },
      { id: 11, name: "▼ О клинике", value2: "action" },
      {
        id: 9,
        name: "Записаться на прием",
        href: "https://medluki.ru/raspisanie-priemov.html",
        mobile: true,
      },
    ],
    links: [
      {
        id: 1,
        name: "Гинекология",
        href: "https://medluki.ru/uslugi/ginekologiya.html",
      },
      {
        id: 2,
        name: "Кардиология",
        href: "https://medluki.ru/uslugi/kardiologiya.html",
      },
      {
        id: 3,
        name: "Лаборатория",
        href: "https://medluki.ru/uslugi/laboratornaya-diagnostika.html",
      },
      {
        id: 4,
        name: "Неврология",
        href: "https://medluki.ru/uslugi/nevrologiya.html",
      },
      {
        id: 5,
        name: "Флебология",
        href: "https://medluki.ru/uslugi/183-sovremennye-metodiki-lecheniya-varikoza.html",
      },
      {
        id: 6,
        name: "Терапия",
        href: "https://medluki.ru/uslugi/terapiya.html",
      },
      {
        id: 7,
        name: "Травматология",
        href: "https://medluki.ru/uslugi/travmatologiya.html",
      },
      {
        id: 8,
        name: "УЗИ",
        href: "https://medluki.ru/uslugi/ultrazvukovaya-diagnostika.html",
      },
      {
        id: 9,
        name: "Урология",
        href: "https://medluki.ru/uslugi/urologiya.html",
      },
      {
        id: 10,
        name: "Хирургия",
        href: "https://medluki.ru/uslugi/sosudistaya-chirurgiya.html",
      },
      {
        id: 11,
        name: "Онкология",
        href: "https://medluki.ru/uslugi/onkologiya.html",
      },
      { id: 12, name: "Лор", href: "https://medluki.ru/uslugiuslugi/lor.html" },
      {
        id: 13,
        name: "Эндокринология",
        href: "https://medluki.ru/uslugi/endokrinologiya.html",
      },
      {
        id: 14,
        name: "Эндоскопия",
        href: "https://medluki.ru/uslugi/fgds-fibrogastroduodenoskopiya.html",
      },
      {
        id: 15,
        name: "Офтальмология",
        href: "https://medluki.ru/uslugi/oftalmolohiya.html",
      },
      {
        id: 16,
        name: "Педиатрия",
        href: "https://medluki.ru/uslugi/pediatriya1.html",
      },
      {
        id: 17,
        name: "Гастроэнтерология",
        href: "https://medluki.ru/uslugi/gastroenterologiya.html",
      },
      {
        id: 18,
        name: "Нейрохирургия",
        href: "https://medluki.ru/uslugi/nejrohirurgiya.html",
      },
      {
        id: 19,
        name: "Дерматовенерология",
        href: "https://medluki.ru/uslugi/dermatovenerologiya.html",
      },
      {
        id: 20,
        name: "Колоноскопия",
        href: "https://medluki.ru/uslugi/kolonoskopiya.html",
      },
      {
        id: 21,
        name: "Стоматология",
        href: "https://medluki.ru/uslugi/stomatologiya1.html",
      },
      {
        id: 22,
        name: "Косметология",
        href: "https://medluki.ru/uslugi/kosmetologiya.html",
      },
      {
        id: 23,
        name: "Клиническая психология",
        href: "https://medluki.ru/uslugi/klinicheskaya-psihologiya.html",
      },
    ],
    links2: [
      {
        id: 1,
        name: "Персонал",
        href: "https://medluki.ru/personal.html",
      },
      {
        id: 2,
        name: "Видео",
        href: "https://medluki.ru/video.html",
      },
      {
        id: 3,
        name: "Галерея",
        href: "https://medluki.ru/gallery.html",
      },
      {
        id: 4,
        name: "Юридическая информация",
        href: "https://medluki.ru/contacts.html",
      },
    
    ],
  }),
};
</script>

<style>
.logoMedtime{
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
}
.phoneNumber{
  color: #5f5f5f;
}

.v-application--wrap {
  min-height: 50px !important;
}
.mainMenu {
  height: 600px;
  overflow-y: auto;
}
.navButtonsRed {
  margin-top: 2px;
}
</style>
